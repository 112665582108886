import React, { useEffect } from 'react'
import { Box, List, ListItem, ListItemText, Card, CardMedia, CardContent, Typography, Rating, Grid, Pagination, ListItemButton, Divider, useMediaQuery, CircularProgress } from '@mui/material';
import canva from '../../assets/icons/canva.webp';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getAllCardsByGroupNameAndGroupType } from '../../redux/actions/cardsForCustomerActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'animate.css';


function InvitationCardMaster() {


    const params = useParams();
    const [name, setName] = useState();
    console.log("Params", params);
    useEffect(() => {
        if('scrollRestoration' in window.history){
          window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
      }, []);




    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 50;

    // useEffect(() => {
    //     console.log("params.group_path", params.group_path);
    //     // dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit));
    //     getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit);
    // }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        // await setPage(value);
        // let rsp = await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, value));
    };
    const [loading, setLoading] = useState(true);
    const [invitationCards, setInvitationcards] = useState([]);
    const groupType = 'pdf'
    const cards = useSelector((state) => state?.cardforCustomer?.cardForCustomerdata?.data);
    useEffect(() => {
        if (cards) {
            console.log("Cards", cards);
            setInvitationcards(cards.rows);
        }
    }, [cards]);

    useEffect(() => {
        const fetchCardForCustomer = async () => {
            setLoading(true);
            let rsp = await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit));
            console.log("RSP", rsp);
            let total = rsp?.data?.total;
            let cards = rsp?.data?.rows;
            setInvitationcards(cards);
            console.log("Invitation Cards", invitationCards);
            let pages = Math.ceil(total / Number(limit));
            if (pages) {
                setTotalPages(pages);
            }
            setLoading(false);
        };

        fetchCardForCustomer();
    }, [params.group_path, dispatch]);

    const handleNavigation = (card_id) => {
        navigate(`/cards/${params?.group_path}/${card_id}`);
    };

    // Animation
    const [hover, setHover] = useState(null);

    const handleMouseEnter = (index) => {
        setHover(index);
    };

    const handleMouseLeave = () => {
        setHover(null);
    };
   

    return (
        <>
            <Grid item xs={12} sm={9} md={10} sx={{ padding: '32px' }}>
                <Typography variant="h5" sx={{ textAlign: 'left', fontWeight: 800 }}>
                    {params?.group_path?.charAt(0)?.toUpperCase() + params?.group_path?.slice(1)}
                </Typography>
                <Typography sx={{ marginBottom: '30px', textAlign: 'left' }}>
                    Invitation Cards and Videos
                </Typography>

                {/* Show loader when loading */}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {/* Cards Grid */}
                        <Grid container spacing={3}>
                            {invitationCards && invitationCards.length > 0 ? (
                                invitationCards.map((card, firstIndex) =>
                                    card.card_samples && card.card_samples.length > 0 ? (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={firstIndex}>
                                            <Card
                                                onClick={() => handleNavigation(card.id)}
                                                onMouseEnter={() => handleMouseEnter(firstIndex)}
                                                onMouseLeave={handleMouseLeave}
                                                className={hover === firstIndex ? 'animate__animated animate__pulse' : ''}
                                                sx={{ maxWidth: '100%', margin: 'auto' }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    height="280"
                                                    image={card.card_samples[0].url}
                                                    alt={card.card_samples[0].thumbnail}
                                                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: 1 }}>
                                                        {card.card_name}
                                                    </Typography>
                                                    <Rating value={card.card_rating} readOnly />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ) : (
                                        <Typography key={firstIndex}>Please wait while we are fetching card details...</Typography>
                                    )
                                )
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="h3" align="center">No Data Available</Typography>
                                    <Typography variant="h6" align="center"> We are uploading data in this section</Typography>
                                </Grid>
                            )}
                        </Grid>


                        {/* Pagination */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                )}
            </Grid>
        </>
    )
}

export default InvitationCardMaster