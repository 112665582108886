import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getAllCardSuggestionRequest = () => ({
    type: actionTypes.GET_ALL_CARD_SUGGESTION_REQUEST,
});

export const getAllCardSuggestionSuccess = (data) => ({
    type: actionTypes.GET_ALL_CARD_SUGGESTION_SUCCESS,
    payload: data,
});

export const getAllCardSuggestionFailure = (error) => ({
    type: actionTypes.GET_ALL_CARD_SUGGESTION_FAILURE,
    payload: error
});

export const getAllCardSuggestionData = (group_path, group_type, page, limit) => {
    return async (dispatch) => {
        dispatch(getAllCardSuggestionRequest());
        try{
            const {data} = await api.getAllCardsSuggestion(group_path, group_type, page, limit);
            dispatch(getAllCardSuggestionSuccess(data));
            return data;

        }catch (error){
            dispatch(getAllCardSuggestionFailure());
        }
    };
};