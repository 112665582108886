import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'

function IosAppPage() {
    useEffect(() => {
        if('scrollRestoration' in window.history){
          window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
      }, []);
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f0f0f0',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h3" gutterBottom >
                Our IOS App will be available from
            </Typography>
            <Typography variant="h5" color="textSecondary">
                20 Oct 2024 00:01 AM
            </Typography>
        </Box>
    )
}

export default IosAppPage