import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItemButton, ListItemText, Box, MenuItem, Divider, Button } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledMenu from './StyledMenu';
import { useNavigate } from 'react-router-dom';

const DrawerComp = ({ openMenu, handleMenuClick, handleClose, menuStates, buttonLabels, menuItems }) => {
    const [open, setOpen] = useState(false);

    console.log("menu item in drawer", menuItems);

    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleMenuClose = () => {
        handleClose();
        // Ensure drawer remains open when menu is closed
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    const handleNavigation = (path_url) => {
        navigate(`/cards/${path_url}`);
        handleMenuClose();
    };

    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <List sx={{ width: 250 }}>
                    {buttonLabels.map((label, index) => (
                        <ListItemButton key={index} onClick={(e) => handleMenuClick(e, index + 1)}>
                            <ListItemText primary={label} />
                            <KeyboardArrowDownIcon />
                            <StyledMenu
                                id={`drawer-customized-menu-${index + 1}`}
                                anchorEl={menuStates[index].anchorEl}
                                open={openMenu && menuStates[index].isOpen}
                                onClose={handleMenuClose}
                            >
                            
                                {menuItems[index + 1].map((item, itemIndex) => (
                                    <MenuItem key={itemIndex} onClick={() => handleNavigation(item.path)} disableRipple>
                                        {item.label}
                                    </MenuItem>
                                ))}
                               
                            </StyledMenu>
                        </ListItemButton>
                    ))}
                </List>
                <Box sx={{ position: 'absolute', bottom: 0, width: '100%', p: 2 }}>
                    <Button fullWidth sx={{ textTransform: 'none', color: 'black' }}>Login</Button>
                    <Button fullWidth sx={{ textTransform: 'none', color: 'black' }}>SignUp</Button>
                    <Button variant="contained" fullWidth sx={{ textTransform: 'none', color: 'white' }}>Download App</Button>
                </Box>
            </Drawer>
            
            <IconButton sx={{ marginLeft: 'auto' }} onClick={handleDrawerToggle}>
                <MenuRoundedIcon />
            </IconButton>
        </>
    );
};

export default DrawerComp;
