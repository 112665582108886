import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchCardForCustomerRequest = () => ({
    type: actionTypes.FETCH_CARDS_FOR_CUSTOMER_REQUEST,
});

export const fetchCardForCustomerSuccess = (data) => ({
    type: actionTypes.FETCH_CARDS_FOR_CUSTOMER_SUCCESS,
    payload: data,
});

export const fetchCardForCustomerFailure = (error) => ({
    type: actionTypes.FETCH_CARDS_FOR_CUSTOMER_FAILURE,
    payload: error,
});

export const getAllCardsByGroupNameAndGroupType = (group_path, group_type, page, limit) => {
    console.log("getAllCardsByGroupNameAndGroupType called ", group_path, group_type, page, limit)
    return async (dispatch) => {
        dispatch(fetchCardForCustomerRequest());
        try {
            const { data } = await api.getAllCardsByGroupNameAndGroupType(group_path, group_type, page, limit);
            dispatch(fetchCardForCustomerSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardForCustomerFailure());
        }
    };
};




export const getSingleCardDetailsRequest = () => ({
    type: actionTypes.FETCH_CARDS_FOR_CUSTOMER_REQUEST,
});

export const getSingleCardDetailsSuccess = (data) => ({
    type: actionTypes.FETCH_CARDS_FOR_CUSTOMER_SUCCESS,
    payload: data,
});

export const getSingleCardDetailsFailure = (error) => ({
    type: actionTypes.FETCH_CARDS_FOR_CUSTOMER_FAILURE,
    payload: error,
});

export const getSingleCardDetails = (card_id, group_path, group_type) => {
    console.log("getSingleCardDetails called ", group_path, group_type)
    return async (dispatch) => {
        dispatch(getSingleCardDetailsRequest());
        try {
            const { data } = await api.getSingleCardDetails(card_id, group_path, group_type);
            dispatch(getSingleCardDetailsSuccess(data));
            return data;
        } catch (error) {
            dispatch(getSingleCardDetailsFailure());
        }
    };
};


export const getSingleCardDetailsDirectly = async (card_id, group_path, group_type) => {
    console.log("getSingleCardDetails called ", group_path, group_type)
    const { data } = await api.getSingleCardDetails(card_id, group_path, group_type);
    return data;
};



export const createCardForCustomerRequest = () => ({
    type: actionTypes.CREATE_CARDS_FOR_CUSTOMER_REQUEST,
});
export const createCardForCustomerSuccess = (data) => ({
    type: actionTypes.CREATE_CARDS_FOR_CUSTOMER_SUCCESS,
    payload: data
});
export const createCardForCustomerFailure = (error) => ({
    type: actionTypes.CREATE_CARDS_FOR_CUSTOMER_FAILURE,
    payload: error
});

export const createCardForCustomerData = async (card_id, cardData) => {
    const { data } = await api.createCardForCustomer(card_id, cardData);
    return data;
    // console.log("createCardData called", card_id, cardData)
    // return async (dispatch) => {
    //     dispatch(createCardForCustomerRequest());
    //     try {
    //         console.log("Inside try block")
    //         const { data } = await api.createCardForCustomer(card_id, cardData);
    //         console.log("Data after Api call",data);
    //         dispatch(createCardForCustomerSuccess(data));
    //         return data;
    //     } catch (error) {
    //         dispatch(createCardForCustomerFailure(error));
    //     }
    // };
};

export const cardPaymentSuccess = async (card_id, paymentData) => {
    const { data } = await api.cardPaymentSuccess(card_id, paymentData);
    return data;
};