import axios from "axios";

const AccountAPIPublic = axios.create({
    baseURL: `https://account.rajoffset.com/`,
    // baseURL: `http://localhost:3000/`,
});

// AccountAPIPublic.interceptors.request.use((req) => {
//     if (localStorage.getItem("access_token")) {
//         req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
//     }
//     return req;
// });

AccountAPIPublic.interceptors.response.use((response) => {
    console.log("Interceptor Response", response);
    return response;
}, (error) => {
    console.log("Interceptor Error", error);
    if (error.response && error.response?.data) {
        return Promise.reject(error.response?.data);
    }
    return Promise.reject(error.message);
})

const APIEnginePublic = axios.create({
    baseURL: `https://engine.rajoffset.com/`,
    // baseURL: `http://localhost:6000/`,
});

const APIEnginePrivate = axios.create({
    baseURL: `https://engine.rajoffset.com/`,
});

APIEnginePrivate.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
        console.log("LocalStorage", localStorage.getItem("access_token"));
        req.headers.Authorization = `Bearer ${localStorage.getItem("access_token").replaceAll('"', '')}`;

    }
    return req;
});

APIEnginePrivate.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.data) {
            return Promise.reject(error.response?.data);
        }
        return Promise.reject(error.message);
    }
);


export const login = (form) => AccountAPIPublic.post(`web/v1/auth-step1/`, form);

export const varifyOtp = (form) => AccountAPIPublic.post(`web/v1/auth-step2/`, form);

export const faq = (form) => AccountAPIPublic.get(`common/web/faq/v1/`, form);

export const reportIssue = (form) => AccountAPIPublic.get(`common/web/report-issue/v1/`, form);

export const createReportIssue = (form) => AccountAPIPublic.post(`common/web/report-issue/v1/`, form);

export const newDesign = () => AccountAPIPublic.get(`common/web/new-design/v1/`);

export const newDesignRequest = (form) => AccountAPIPublic.post(`common/web/new-design/v1/`,);

export const getAllCardsByGroupNameAndGroupType = (group_path, group_type, page, limit) => APIEnginePublic.get(`/invitation/card/v1/?group_path=${group_path}&group_type=${group_type}&page=${page}&limit=${limit}`);

export const getSingleCardDetails = (card_id, group_path, group_type) => APIEnginePublic.get(`/invitation/card/v1/${card_id}/?group_path=${group_path}&group_type=${group_type}`);

export const createCardForCustomer = (id, form) => APIEnginePublic.post(`/invitation/card/v1/${id}`, form);

export const cardPaymentSuccess = (id, form) => APIEnginePrivate.post(`/invitation/card-payment-success/v1/${id}`, form);

export const getAllCardsSuggestion = (group_path, group_type, page, limit) => APIEnginePrivate.get(`/invitation/card-suggestion/v1/?group_path=${group_path}&group_type=${group_type}&page=${page}&limit=${limit}`);

export const getAllCards = (page, limit) => APIEnginePrivate.get(`/invitation/card-attempt/v1/`);

export const completeProfileWithUserDetails = (form) => APIEnginePrivate.post(`/web/v1/complete-profile/`,form);