import React, { useEffect } from 'react';
import { Grid, Box, Typography, Paper, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../package.json';
import GooglePlay from '../../src/assets/icons/GooglePlay.png';
import appleStore from '../../src/assets/icons/appleStore.jpg';


function Footer() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const appVersion = packageJson.version;


  const navigate = useNavigate();
  const menuItems = [
    { label: 'FAQ', path: '/faq' },
    { label: 'About Us', path: '/about-us' },
    { label: 'Contact Us', path: '/contact-us' },
    { label: 'Terms & Conditions', path: '/terms-and-conditions' },
    { label: 'Privacy Policy', path: '/privacy-policy' },
    { label: 'Refund Policy', path: '/refund-policy' },
    { label: 'Delete Account Policy', path: '/delete-account-policy' },
    { label: 'Support Ticket', path: '/support-ticket' },
  ];

  const invitationCategory = [
    { label: 'Engagement Invitation', path: 'engagement' },
    { label: 'Wedding Invitation', path: 'wedding' },
    { label: 'House Warming Invitation', path: 'griha-pravesh' },
    { label: 'Shop Grand Opening', path: 'shop-opening' },
    { label: 'Mundan Invitation', path: 'mundan-card' },
    { label: 'Naming Ceremony', path: 'naming-ceremony' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleInvitationCardNavigation = (path_url) => {
    navigate(`/cards/${path_url}`);
  };
  const handleDownloadnavigation = () => {
    navigate(0);
    navigate(`/android-app`);

  }
  useEffect(() => {
    if('scrollRestoration' in window.history){
      window.history.scrollRestoration = 'manual';
    }
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    handleScrollToTop();

    return () => {
      if( 'scrollRestoration' in window.history){
        window.history.scrollRestoration = 'auto';
      }
    };
    
  }, []);


  return (
    <div>
      {/* Stats Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#4a7856',
          color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'white',
          padding: 2,
          // Added border for visibility in dark mode
        }}
      >
        <Grid container justifyContent="space-around">
          {['10000+', '1000+', '5000+', '20+'].map((stat, index) => (
            <Grid item key={index}>
              <Typography variant="h4" 
                sx={{
                  fontSize: { xs: '35px'}
                }}>
                {stat}
              </Typography>
              <Typography variant="body1" sx={{}}>
                {['Active Visitor', 'App Downloads', 'Digital Invitations', 'User by State'][index]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#d4e4ed',
          padding: 4,
          textAlign: 'center',
          // Added border for visibility in dark mode
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: isDarkMode ? 'black' : 'black' }}>
          What Customers say about Us
        </Typography>
        <Grid container justifyContent="center" spacing={3}>
          {[
            { text: '"Local for vocal!"', msg: 'Very Easy to use for bussinessman too. Local for vocal Proudly Made in Bharat🇮🇳', author: 'Abhishek Singh' },
            { text: '"Very easy and nice"', msg: 'Very easy to use nic aap made in India for business & family celebration', author: 'Akash Singh' },
            { text: '"Very Good App"', msg: 'Very good app .', author: 'Ankit Mishra' },
            { text: '"Nice app"', msg: 'Nice app .', author: 'Sahil Patel' }
          ].map((testimonial, index) => (
            <Grid item key={index}>
              <Box>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#f7e6e0',
                    maxWidth: 300,

                  }}
                >
                  <Typography variant="body1" sx={{ textAlign: 'left', fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
                    {testimonial.text}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.925rem', color: isDarkMode ? 'black' : 'black' }}>
                    {testimonial.msg}
                  </Typography>
                </Paper>
                <Typography variant="caption" sx={{ display: 'block', marginTop: 1, textAlign: 'right', color: isDarkMode ? 'black' : 'black' }}>
                  - {testimonial.author}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* App Download Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f7d5cc',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <Typography variant='h6'>Download our mobile app from</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography
            variant="h6"
            noWrap
            component="div" sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
            onClick={() => navigate(`/android-app`)}
          >
            <img src={GooglePlay} height={'50px'} alt="Logo" />
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
            onClick={() => navigate(`/ios-app`)}
          >
            <img src={appleStore} height={'45px'} alt="Logo" />
          </Typography>
        </Box>

      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'white',
          color: isDarkMode ? 'black' : 'black',
          padding: 4,
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
              RajOffset
            </Typography>
            <Typography variant="body1" sx={{ paddingRight: '15px', paddingTop: '10px', color: isDarkMode ? 'black' : 'black' }}>
              RajOffset is an online platform for creating Customized Wedding and other personalized invitations for your loved ones...
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
              Top Links
            </Typography>
            {invitationCategory.map((item, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{ paddingTop: '10px', color: isDarkMode ? 'black' : 'black', cursor: 'pointer' }}
                onClick={() => handleInvitationCardNavigation(item.path)}
              >
                 {item.label}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
              Support
            </Typography>
            {menuItems.map((item, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{ paddingTop: '10px',  color: isDarkMode ? 'black' : 'black', cursor: 'pointer' }}
                onClick={() => handleNavigation(item.path)}
              >
                {item.label}
              </Typography>
            ))}
          </Grid>
          <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'left' }}>
            Version {appVersion} - powered by Awesome Innovators
          </Typography>
        </Grid>
      </Box>
    </div>
  );
}

export default Footer;
