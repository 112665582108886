import * as actionTypes from "../actionTypes.js";

const initialState = {
    getAllCardSuggestion: [],
    loading: false,
    error: null,
}

const getAllCardSuggestionReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.GET_ALL_CARD_SUGGESTION_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_CARD_SUGGESTION_SUCCESS:
            return{
                ...state,
                getAllCardSuggestion: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_ALL_CARD_SUGGESTION_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }

};
export default getAllCardSuggestionReducer;