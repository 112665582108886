import React from 'react';
import { Box, List, ListItem, ListItemText, Grid, Divider, useMediaQuery, ListItemButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';

const categories = [
  { label: 'Dashboard', path: '/dashboard/complete-profile' },
  { label: 'My Cards', path: '/dashboard/my-cards' },
  { label: 'Invoices', path: '/dashboard/invoices' },
  // { label: 'Family and Friends', path: '/dashboard/family-and-friends' },
  // { label: 'Feedback', path: '/dashboard/feedback' },
  { label: 'Support Ticket', path: '/support-ticket' },
];

const Dashboard = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', overflowX: 'hidden', padding: '0px', marginTop: 4 }}>
      {isMatch ? (
        <Grid container spacing={1} sx={{ marginX: 'auto' }}>
          <Grid
            item
            xs={12}
            sm={9}
            md={8}
            sx={{
              padding: '4px',
              minHeight: '100vh',
            }}
          >

            <Outlet />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              padding: '3px 0',
              backgroundColor: '#e0e0e0',
              height: 'auto',
            }}
          >
            <List>
              {categories.map((category, index) => (
                <React.Fragment key={index}>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(category.path)}> {/* Navigate onClick */}
                      <ListItemText sx={{ color: '#616161' }} primary={category.label} />
                    </ListItemButton>
                  </ListItem>
                  <Divider sx={{ borderStyle: 'dotted', borderColor: '#aaa', borderWidth: '0 0 1px 0' }} />
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} sx={{ marginX: 'auto' }}>
          {/* Sidebar */}
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              padding: '5px 0',
              backgroundColor: '#e0e0e0',
              height: '100vh',
              position: 'sticky',
              top: 0,
            }}
          >
            <List className="animate__animated animate__slideInLeft">
              {categories.map((category, index) => (
                <React.Fragment key={index}>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(category.path)}> {/* Navigate onClick */}
                      <ListItemText sx={{ color: '#616161' }} primary={category.label} />
                    </ListItemButton>
                  </ListItem>
                  <Divider sx={{ borderStyle: 'dotted', borderColor: '#aaa', borderWidth: '0 0 1px 0' }} />
                </React.Fragment>
              ))}
            </List>
          </Grid>

          {/* Main Content */}
          <Grid
            item
            xs={12}
            sm={9}
            md={8}
            sx={{
              padding: '2px',
              marginRight: 2,
              minHeight: '100vh',
            }}
          >
            <Outlet />
          </Grid>
        </Grid>
      )}

    </Box>
  );
};

export default Dashboard;
