import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Navbar';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, Card, CardContent, CardMedia, Dialog, Grid, Rating, Toolbar } from '@mui/material';
import wedding from '../../assets/icons/wedding.webp'
import { Description, ThumbUp, HeadsetMic } from '@mui/icons-material';
import cards from '../../assets/icons/cards.jpg';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCardSuggestionData } from '../../redux/actions/getAllCardSuggestionActions'


import engagement from '../../assets/main-icon/engagement.png'
import wedding1 from '../../assets/main-icon/wedding1.png';
import biodata from '../../assets/main-icon/biodata.png';
import houseWarming from '../../assets/main-icon/houseWarming.png';
import haldi from '../../assets/main-icon/haldi.png';
import sangeet from '../../assets/main-icon/Sangeet.png';
import MuslimWedding from '../../assets/main-icon/MuslimWedding.png';
import Mundan from '../../assets/main-icon/Mundan.png';
import Birthday from '../../assets/main-icon/Birthday.png';
import Annaprashan from '../../assets/main-icon/Annaprashan.png'
import Anniversary from '../../assets/main-icon/Anniversary.png'




const categories = [
  { label: 'Engagement Card', img: engagement },
  { label: 'Marriage Invitation', img: wedding1 },
  { label: 'Marriage Biodata', img: biodata },
  { label: 'House Warming Card', img: houseWarming },
  { label: 'Haldi Invitation', img: haldi },
  { label: 'Sangeet Invitation', img: sangeet },
  { label: 'Muslim Wedding Cards', img: MuslimWedding },
  { label: 'Mundan Card', img: Mundan },
  { label: 'Birthday Invitation', img: Birthday },
  { label: 'Annaprashan Invitation', img: Annaprashan },
  { label: 'Anniversary Invitation', img: Anniversary },
];

const invitations = [
  { title: 'Serenity ', image: cards, rating: 5 },
  { title: 'Bird of Charm', image: cards, rating: 5 },
  { title: 'Loving Paradise', image: cards, rating: 5 },
  { title: 'Dastaan', image: cards, rating: 5 },
  { title: 'Sweet William', image: cards, rating: 5 },
  { title: 'Serenity ', image: cards, rating: 5 },
  { title: 'Bird of Charm', image: cards, rating: 5 },
  { title: 'Loving Paradise', image: cards, rating: 5 },
  { title: 'Dastaan', image: cards, rating: 5 },
  { title: 'Sweet William', image: cards, rating: 5 },
];

const steps = [
  {
    number: 1,
    title: 'Choose Digital Invitation',
  },
  {
    number: 2,
    title: 'Fill Details',
  },
  {
    number: 3,
    title: 'Payment & download instantly',
  },
];


const HomePage = () => {
  const textBoxRef = useRef(null);
  const [textBoxHeight, setTextBoxHeight] = useState(0);

  useEffect(() => {
    if (textBoxRef.current) {
      setTextBoxHeight(textBoxRef.current.offsetHeight);
    }
  }, []);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  // fetch card suggestion data 
  const params = useParams();
  console.log("params", params);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 50;

  const cardSuggestion = useSelector((state) => state?.
    getAllCardSuggestions?.getAllCardSuggestion?.data);

  console.log("Card suggestion in home page", cardSuggestion);

  

  useEffect(() => {

    const fetchAllCardSuggestion = async () => {
      await dispatch(getAllCardSuggestionData("engagement", "pdf", page, limit));
    };
    fetchAllCardSuggestion();

  }, [dispatch]);




  //display cards img

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedimage] = useState(null);


  const handleClickOpen = (img) => {
    setSelectedimage(img);
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setSelectedimage(null);
  }

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          marginTop: 5,
          alignItems: { xs: 'center', md: 'flex-start' },
          gap: 10
        }}
      >
        <Box
          ref={textBoxRef}
          sx={{
            marginLeft: { xs: 2, sm: 4, md: 8, lg: 20 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: { xs: 1, sm: 2, md: 4, lg: 8 },
            width: { md: 'auto' },
          }}
        >
          <Typography
            variant="h4"
            color="initial"
            sx={{
              fontSize: { xs: 'h6.fontSize', sm: 'h5.fontSize', md: 'h4.fontSize', lg: 'h4.fontSize', },
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
            }}
          >
            One Platform For All Your
          </Typography>
          <Typography
            variant="h4"
            color="initial"
            sx={{
              fontSize: { xs: 'h6.fontSize', sm: 'h5.fontSize', md: 'h4.fontSize', lg: 'h4.fontSize', },
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
            }}
          >
            Digital Invitations
          </Typography>
          <Typography
            variant="body1"

            sx={{
              fontSize: { xs: '0.725rem', sm: '0.725rem', md: '0.875rem', lg: '0.875rem', },
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'skyblue'
            }}
          >
            Biodata, Wedding Invitation Card, Engagement Haldi
          </Typography>
          <Typography
            variant="body1"

            sx={{
              fontSize: { xs: '0.725rem', sm: '0.725rem', md: '0.875rem', lg: '0.875rem', },
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'skyblue'
            }}
          >
            House Warming Ceremony, Shop Grand Opening
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: isDarkMode ? '#BB86FC' : '#e53935',
                color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'white',
                marginRight: 1,
                marginTop: { xs: 1 },
              }}
            >
              All Biodata
            </Button>
            <Button variant="contained" sx={{
              marginTop: { xs: 1 },
              backgroundColor: isDarkMode ? '#BB86FC' : '#e53935',
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'white',
            }}>
              Wedding PDF Card @Rs.11/
            </Button>
          </Box>
          <Typography
            variant="body1"
            color="black"
            sx={{
              fontSize: { xs: '0.725rem', sm: '0.725rem', md: '0.875rem', lg: '0.875rem', },
              fontWeight: 600,
              marginTop: 1,
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',

            }}
          >
            Best, Trusted & Cheapest Invitations
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,
            height: `${textBoxHeight}px`, // Set height based on the text box height
            width: 'auto',
          }}
        >
          <img
            src={wedding}
            alt="Logo"
            style={{
              maxHeight: '100%',
              width: 'auto',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f5f5f5', padding: '20px 0' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">

          {/* Unique Biodata Design */}
          <Grid item xs={12} sm={4}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Description sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black' }}>
                  Unique Design at Best Price
                </Typography>
                <Typography variant="body2" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                  We provide Best Digital Invitations with an impressive and beautiful design exclusively on RajOffset Platform.
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Lowest Price Guarantee */}
          <Grid item xs={12} sm={4}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <ThumbUp sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black' }}>
                  Lowest Price Guarantee
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                  We provides the best quality product at the cheapest price. Customer's satisfaction is our main priority.
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* 24/7 Support */}
          <Grid item xs={12} sm={4}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <HeadsetMic sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black' }}>
                  24/7 WhatsApp Support
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                  Our Support team is available 24/7 to support any issues or suggestions. Connect with us on WhatsApp to resolve your issue anytime.
                </Typography>
              </Box>
            </Box>
          </Grid>

        </Grid>
      </Box>


      <Box sx={{ padding: '20px 0' }}>
        <Typography
          variant="h3"
          color="initial"
          sx={{
            textAlign: 'center',
            marginBottom: 2,
            color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
          }}
        >
          Top Category
        </Typography>
        <Grid container spacing={2} justifyContent="center" sx={{ width: '90%', marginX: 'auto' }}>
          {categories.map((category, index) => (
            <Grid item xs={6} sm={4} md={3} lg={1.5} key={index} textAlign="center">
              <Card sx={{ maxWidth: 150, maxHeight: 'full', margin: 'auto' }}>
                <CardMedia
                  component="img"
                  height="100"
                  image={category.img}
                  alt={category.label}
                  sx={{ objectFit: 'cover', borderRadius: 1 }}
                  onClick={() => handleClickOpen(category.img)}
                />
              </Card>
              <Typography
                variant="body1"
                sx={{
                  marginTop: '10px',
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
                }}
              >
                {category.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>


      <Box p={3}>
        <Typography variant="h4" align="center" gutterBottom sx={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black' }}>
          Engagement Invitation Cards
        </Typography>
        <Grid container spacing={3}>
          {cardSuggestion?.rows?.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
              {card?.card_samples?.map((img, id) => (
                <Card key={id}>
                  <CardMedia
                    component="img"
                    alt={img.thumbnail}
                    height="240"
                    image={img.url}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                      {card.card_name}
                    </Typography>
                    <Rating value={card.card_rating} readOnly />
                  </CardContent>
                </Card>
              ))}

            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" mt={4}>
          <Button onClick={() => navigate(`/cards/engagement`)} variant="contained" color="secondary" sx={{ textTransform: 'none', backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350', borderEndEndRadius: 3 }}>
            Browse More Engagement Invites
          </Button>
        </Box>
      </Box>

      {/* <Box p={3}>
        <Typography variant="h4" align="center" gutterBottom sx={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black' }}>
          Wedding Invitation Cards
        </Typography>
        <Grid container spacing={3}>
          {cardSuggestion?.rows?.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
              {card?.card_samples?.map((img, id) => (
                <Card key={id}>
                  <CardMedia
                    component="img"
                    alt={img.thumbnail}
                    height="240"
                    image={img.url}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                      {card.card_name}
                    </Typography>
                    <Rating value={card.card_rating} readOnly />
                  </CardContent>
                </Card>
              ))}

            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" mt={4}>
          <Button onClick={() => navigate(`/cards/engagement`)} variant="contained" color="secondary" sx={{ textTransform: 'none', backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350', borderEndEndRadius: 3 }}>
            Browse More Engagement Invites
          </Button>
        </Box>
      </Box>

      <Box p={3}>
        <Typography variant="h4" align="center" gutterBottom sx={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black' }}>
          Birthday Invitation Cards
        </Typography>
        <Grid container spacing={3}>
          {cardSuggestion?.rows?.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
              {card?.card_samples?.map((img, id) => (
                <Card key={id}>
                  <CardMedia
                    component="img"
                    alt={img.thumbnail}
                    height="240"
                    image={img.url}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                      {card.card_name}
                    </Typography>
                    <Rating value={card.card_rating} readOnly />
                  </CardContent>
                </Card>
              ))}

            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" mt={4}>
          <Button onClick={() => navigate(`/cards/engagement`)} variant="contained" color="secondary" sx={{ textTransform: 'none', backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350', borderEndEndRadius: 3 }}>
            Browse More Engagement Invites
          </Button>
        </Box>
      </Box> */}
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Selected" style={{ width: "100%", height: "auto" }} />
      </Dialog>
    </div>
  );
};

export default HomePage;
